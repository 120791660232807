import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo";

interface Props {}

const VzornikBarevPage = (props: Props) => {
  return (
    <Layout>
      <SEO title="Vzorník barev" />
      <h1>Vzorník barev</h1>
      <h2 className="text-center">Tkané etikety</h2>
      <div className="flex justify-center mb-10">
        <StaticImage
          src="../images/vzornik-barev/tkane-etikety.jpg"
          alt="Vzorník tkaných etiket"
        />
      </div>
      <h2 className="text-center">Termotransfer</h2>
      <div className="flex justify-center mb-10">
        <StaticImage
          src="../images/vzornik-barev/termotransfer.png"
          alt="Vzorkník termotransferu"
        />
      </div>
      <h2 className="text-center">Tisk z folie</h2>
      <div className="flex justify-center mb-10">
        <StaticImage
          src="../images/vzornik-barev/tisk-z-folie.png"
          alt="Vzorník tisku z folie"
        />
      </div>
    </Layout>
  );
};

export default VzornikBarevPage;
